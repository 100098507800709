import React from 'react';

const MediaLogo = (props) => {
  return (
    <a
      className='media-logo'
      href={props.url}
      target="_blank"
      rel="noopener noreferrer">
      <div>
        <img
          className="logo"
          src={props.path}
          alt={props.alt} />
      </div>
    </a>
  )
}

export default MediaLogo;
import React from 'react';

import './Project.css';

const Project = (props) => {
  return (
    <div className="project">
      <a
        href={props.link} target="_blank"
        rel="noopener noreferrer">
        <div className="box">
          <img
            src={props.src}
            alt={props.alt} />
          <div className="photo-overlay">
            <h3>{props.title}</h3>
            <p>{props.text}</p>
          </div>
        </div>
      </a>
    </div>
    
  )
}

export default Project;
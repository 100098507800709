import React from 'react';

import MediaLogo from '../MediaLogo/MediaLogo'

import linkedinLogo from '../../images/linkedin-logo.png';
import gitHubLogo from '../../images/github-logo.png';
import twitterLogo from '../../images/twitter-logo.png';

import './Footer.css';

const Footer = (props) => {
  const handleClick = (e) => {
    const bonus = document.getElementById('footer_inner-container');
    console.log(bonus)
    if(bonus.style.maxHeight) {
      bonus.style.padding = null
      bonus.style.maxHeight = null
    } else {
      bonus.style.padding = '2rem'
      bonus.style.maxHeight = bonus.scrollHeight + 'px'
    }
  }
  return (
    <div className='footer_container'>
      
      <div className='footer_media-logo_container'>
        <MediaLogo
          url='https://www.linkedin.com/in/gregorymarquet'
          path={linkedinLogo}
          alt="Linkeding Logo" />
        <MediaLogo
          url='https://github.com/gregmarquet'
          path={gitHubLogo}
          alt="GitHub Logo" />
        <MediaLogo
          url='https://twitter.com/gregmarquet'
          path={twitterLogo}
          alt="Twitter Logo" />
      </div>
      
      <div id="footer_inner-container">
        <div className='bonus'>
          <h1 className='bonus-title'>
              Bonus
          </h1>
          <p>Congrats! You unlocked the bonus. To reward your curiousity, I will share with you a little bit about me.</p>
          <p>I was not always a professional developer, I used to be a Circus performer. Enjoy the video.</p>
          <div className="video-wrapper">
            <iframe title="freres" width="560" height="349" src="https://www.youtube.com/embed/RV1S91q5Vpo?&rel=0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div> 
        </div>
      </div>

      <p>Made with <span
        className="redheart"
        onClick={handleClick}>&hearts;
      </span> by Greg Marquet</p>
      
      
    </div>
  )
}

export default Footer;
import React, { Component } from 'react';

import './Contact.css';

class Contact extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      subject: '',
      message: ''
    }
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const email = this.state.email
    const subject = this.state.subject
    const message = this.state.message
    console.log(email)
    console.log(subject)
    console.log(message)
    document.getElementById('sent').style.maxHeight = '50px';
    document.getElementById('sent').style.visibility = 'visible';
    setTimeout(() => {
      document.getElementById('sent').style.visibility = 'hidden';
      document.getElementById('sent').style.maxHeight = '0';
    }, 2000);
    this.setState({
      email: '',
      subject: '',
      message: ''
    })
  }

  render() {
    return (
      <div className='contact_inner-container'>
        <h1 className='contact-title'>
          Contact Me
        </h1>
        <div id='sent'>Your message was sent!</div>
        <form 
          name="contact"
          method="post"
          action="/"
        >
          <input type="hidden" name="form-name" value="contact" />
          <input
            className='contact-textfield'
            type="email"
            name="email"
            id="email"
            placeholder="Email"
            onChange={this.handleChange}
            value={this.state.email}
            required/>
          <input 
            className='contact-textfield'
            type="text"
            name="subject"
            placeholder="Subject"
            id="subject"
            onChange={this.handleChange}
            value={this.state.subject}
            required/>
          <textarea 
            className='contact-textarea'
            name="message"
            id="message"
            placeholder="Enter you message" 
            cols="30" 
            rows="10"
            onChange={this.handleChange}
            value={this.state.message}
            required>
          </textarea>
          <input
            className="contact-submit"
            type="submit"
            value="Send Message"/>
        </form>
      </div>
    )
  }
}

export default Contact;
import React from 'react';

import './Work.css';

const Work = (props) => {
  return (
    <div className='work_inner-container'>
      <h1 className='work-title'>Work</h1>
      <h3 className='work-date'>2017-2018</h3>
      <h2 className='work-position'>Front End Developer at JVB Conseil</h2>
      <p className='work-text'>Managed all front end implementations for their coach’s platform, redesigned and rebuilt their website using HTML, CSS and JavaScript and designed and Developed HR tools using React</p>

      <h3 className='work-date'>2016-2017</h3>
      <h2 className='work-position'>Freelance Front End Developer</h2>
      <p className='work-text'> Designed and built modern and compelling responsive websites based on client specifications and helped clients think through their technical goals</p>
    </div>
  )
}

export default Work;
import React from 'react';

import './About.css';

const About = (props) => {
  return (
      <div className='about_inner-container'>
        <h1 className='about-title'>About me</h1>
        <p className='about-text'>Front-end Developer with a passion for improving people’s lives through technology. Proficient in code, application and website development. Always looking for opportunities to learn and adapt to different environments/challenges. I am eager to connect with people who share my passion for people, technology, and entrepreneurship.</p>
      </div>
  )
}

export default About;
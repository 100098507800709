import React, { Component } from 'react';
import linkedinLogo from './images/linkedin-logo.png';
import gitHubLogo from './images/github-logo.png';
import twitterLogo from './images/twitter-logo.png';
 

import MediaLogo from './components/MediaLogo/MediaLogo';
import About from './components/About/About';
import Projects from './components/Projects/Projects';
import Work from './components/Work/Work'
import Education from './components/Education/Education'
import Contact from './components/Contact/Contact'
import Footer from './components/Footer/Footer'

import './App.css';

class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="App_header">
          <h1 className="App_header-h1">Greg Marquet</h1>
          <h2 className="App_header-h2">Front End Developer</h2>
          <div className='media-logo_container'>
            <MediaLogo
              url='https://www.linkedin.com/in/gregorymarquet'
              path={linkedinLogo}
              alt="Linkeding Logo" />
            <MediaLogo
              url='https://github.com/gregmarquet'
              path={gitHubLogo}
              alt="GitHub Logo" />
            <MediaLogo
              url='https://twitter.com/gregmarquet'
              path={twitterLogo}
              alt="Twitter Logo" />
          </div>
        </header>
        <div className='content_container'>
          <About />
          <Projects />
          <Work />
          <Education />
          <Contact />
          <Footer />
        </div>

        
      </div>
    );
  }
}

export default App;

import React from 'react';

import './Education.css';

const Education = (props) => {
  return (
    <div className='education_inner-container'>
      <h1 className='education-title'>Education</h1>
      <h3 className='education-date'>2016</h3>
      <h2 className='education-subject'>Software Engineering</h2>
      <h3 className='education-school'>The Flatiron School</h3>
      <p className='education-text'>I graduated from the software engineering immersive bootcamp where I learned advanced concepts in Ruby on Rails and JavaScript, including MVC architectural pattern, SOLID principles, Object Oriented Programming and Functional Programming paradigms, AJAX, RESTful API, Git and GitHub version control, npm, Yarn, Grunt, SQL and  and integration testing.</p>

      <h3 className='education-date'>2015</h3>
      <h2 className='education-subject'>Front End Development</h2>
      <h3 className='education-school'>General Assembly</h3>
      <p className='education-text'>I graduated for the front end development course where I studied HTML, CSS, JavaScript, Bootstrap and focused on building compelling responsive websites.</p>

      <h3 className='education-date'>2015</h3>
      <h2 className='education-subject'>Graphic Design</h2>
      <h3 className='education-school'>General Assembly</h3>
      <p className='education-text'>I graduated for the graphic design course where I studied Photoshop, Sketch, color theory, typography, research, wire- framing, UX fundamentals, gestalt principles in web design and responsive design.</p>
    </div>
  )
}

export default Education;
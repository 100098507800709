import React from 'react';

import Project from '../Project/Project';

import growmster from '../../images/growmster.png'
import inspired from '../../images/inspired.png'
import giphyx from '../../images/giphyx.png'
import jenSite from '../../images/jen-site.jpg'
import jenArtSite from '../../images/jen-art-site.jpg'
import clock from '../../images/clock.jpg'
import inspiration from '../../images/inspiration.jpg'
import temperature from '../../images/temperature.jpg'
import './Projects.css';

const Projects = (props) => {
  return (
    <div className='projects_inner-container'>
      <h1 className='projects-title'>Projects</h1>
      <div className="projects_container">
        <Project
          link='https://gregmarquet-inspired.netlify.com/'
          src={inspired}
          alt='Stars'
          title='Inspired'
          text='A React app that brings you inpiring images' />
        <Project
          link='https://gregmarquet-growmster.netlify.com/'
          src={growmster}
          alt='Sliders'
          title='Growmster'
          text='A React app that helps you to grow' />
        <Project
          link='https://gregmarquet-giphyx.netlify.com/'
          src={giphyx}
          alt='Gifs'
          title='Giphyx'
          text='A React app that allow to search the Giphy API' />
        <Project 
          link='http://jennifersipila.com/'
          src={jenSite} 
          alt='Building'
          title='Jen Sipila'
          text='A single page website. I implemented parallax with JS.'/>
        <Project
          link='https://gregmarquet-clock.netlify.com/'
          src={clock}
          alt='Clock'
          title='Clock'
          text='A JavaScript analog clock.' />
        <Project 
          link='https://gregmarquet-inspiration.netlify.com/'
          src={inspiration}
          alt='Mountain'
          title='Inspiration'
          text='A JavaScript web-app that gives you inspirational quotes.'/>
        <Project
          link='https://gregmarquet-temperature.netlify.com/'
          src={temperature}
          alt='Banch'
          title='Temperature Converter'
          text='A JavaScript temperature converter.' />
        <Project
          link='http://jennifersipila.art/'
          src={jenArtSite}
          alt='Drawings'
          title='Jen Sipila Art'
          text='Static website using ekko-lightbox for the image gallery.' />
      </div>
    </div>
  )
}

export default Projects;